.NewNote form {
    padding-bottom: 15px;
  }
  
  .NewNote form textarea {
    height: 50px;
    font-size: 18px;
  }

  .photo {
    height: 200px;
    width: 200px; 
}