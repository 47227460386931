.Notes form {
    padding-bottom: 15px;
  }
  
  .Notes form textarea {
    height: 75px;
    font-size: 18px;
  }


  .photo {
    height: 200px;
    width: 200px; 
}